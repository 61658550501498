import React, { useState, useEffect } from 'react';
import { Button, Label, Input, Card, CardHeader, Table, CardBody } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchLenderNames } from '../loan-funder/loan-funder.reducer';
import { fetchBalance, getLenderId, addTransaction, viewTransactionHistory } from './lender-investment.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export const LenderInvestment = () => {
  const dispatch = useAppDispatch();
  const allLenderNames = useAppSelector(state => state.loanFunder?.allLenderNames);
  const lenderIdList = useAppSelector(state => state.lenderInvestment?.lenderIdList);
  const lenderBalance = useAppSelector(state => state.lenderInvestment?.lenderBalance?.balance);
  const transactionTable = useAppSelector(state => state.lenderInvestment?.transactionTableData);
  const errorMessage = useAppSelector(state => state.lenderInvestment?.message);

  const [selectedLenderName, setSelectedLenderName] = useState('');
  const [selectedLenderId, setSelectedLenderId] = useState('');
  const [showBalance, setShowBalance] = useState(false);
  const [showAddTransaction, setShowAddTransaction] = useState(false);
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('Deposit');
  const [transactionNote, setTransactionNote] = useState('');
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);

  const [lenderNameError, setLenderNameError] = useState('');
  const [lenderIdError, setLenderIdError] = useState('');
  const [amountError, setAmountError] = useState('');
  const [transactionNoteError, setTransactionNoteError] = useState('');

  const resetState = () => {
    setShowBalance(false);
    setShowTransactionHistory(false);
    setAmount('');
    setTransactionType('Deposit');
    setTransactionNote('');
    setShowAddTransaction(false);

    setLenderNameError('');
    setLenderIdError('');
    setAmountError('');
    setTransactionNoteError('');
  };

  const getLenderNames = () => {
    dispatch(fetchLenderNames());
  };

  useEffect(() => {
    getLenderNames();
  }, []);

  const handleLenderName = async event => {
    const selectedName = event.target.value;
    setSelectedLenderName(selectedName);
    setSelectedLenderId('');
    await dispatch(getLenderId(selectedName));
    setAmount('');
    resetState();
  };

  const handleLenderID = async event => {
    const selectedId = event.target.value;
    setSelectedLenderId(selectedId);
    resetState();

    if (selectedId) {
      await dispatch(fetchBalance(selectedId));
      setShowBalance(true);
    }
  };

  const handleViewLenderTransactionHistory = async () => {
    await dispatch(viewTransactionHistory(selectedLenderId));
    setShowTransactionHistory(true);
  };

  const handleCheckBalance = async (lenderId: string) => {
    if (!lenderId) return;
    await dispatch(fetchBalance(lenderId));
  };

  const handleAddTransactionToggle = () => {
    setShowAddTransaction(!showAddTransaction);
  };

  const handleAddTransaction = async () => {
    let isValid = true;

    if (!selectedLenderName) {
      setLenderNameError('Please select a lender name.');
      isValid = false;
    } else {
      setLenderNameError('');
    }

    if (!selectedLenderId) {
      setLenderIdError('Please select a lender ID.');
      isValid = false;
    } else {
      setLenderIdError('');
    }

    if (!amount || isNaN(parseFloat(amount))) {
      setAmountError('Please provide a valid amount.');
      isValid = false;
    } else {
      setAmountError('');
    }

    if (!transactionNote) {
      setTransactionNoteError('Transaction note is required.');
      isValid = false;
    } else {
      setTransactionNoteError('');
    }

    if (!isValid) return;

    try {
      const result = await dispatch(
        addTransaction({ lenderId: selectedLenderId, amount: parseFloat(amount), transactionType, transactionNote }),
      );

      if (result?.meta?.requestStatus === 'fulfilled') {
        await handleCheckBalance(selectedLenderId);
        await handleViewLenderTransactionHistory();
        setShowTransactionHistory(true);
        setShowBalance(true);
        resetState();
      } else {
        console.error('Transaction failed:', result);
      }
    } catch (error) {
      console.error('Failed to add transaction:', error);
    }
  };

  const toggleBalanceVisibility = () => {
    handleCheckBalance(selectedLenderId);
    setShowBalance(!showBalance);
  };

  return (
    <div className="pt-3">
      <div className="row align-items-end dropDownContainer">
        <div className="col-auto content-btn-div">
          <div className="max-div-search px-1">
            <label className="custom-label">Name :</label>
            <select value={selectedLenderName} onChange={handleLenderName} className="custom-input-box">
              <option>Select Lender</option>
              {allLenderNames?.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
            {lenderNameError && <div className="text-danger">{lenderNameError}</div>}
          </div>

          <div className="max-div-search px-1">
            <label className="custom-label">Lender ID :</label>
            <select value={selectedLenderId} onChange={handleLenderID} className="custom-input-box">
              <option>Select Lender ID</option>
              {lenderIdList?.map((lenderId, index) => (
                <option key={index} value={lenderId}>
                  {lenderId}
                </option>
              ))}
            </select>
            {lenderIdError && <div className="text-danger">{lenderIdError}</div>}
          </div>

          {selectedLenderId && (
            <div className="max-div-search px-1">
              <label className="custom-label">Current Balance:</label>
              <input id="balanceInput" value={lenderBalance ?? 'XX,XXX,XX'} readOnly className="custom-input-box" />
            </div>
          )}
        </div>
        <div className="col text-end content-btn-div">
          {selectedLenderId && (
            <>
              <div className="max-div-search px-1 pt-1">
                <Button onClick={handleViewLenderTransactionHistory} className="generate-report-btn">
                  Transaction History
                </Button>
              </div>
              {!showAddTransaction && (
                <div className="max-div-search px-1 pt-1">
                  <Button onClick={handleAddTransactionToggle} className="generate-report-btn">
                    Add Transaction
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showAddTransaction && (
        <Card className="mt-4 card-custom">
          <CardBody>
            <div className="d-flex justify-content-between">
              <div className="sub-heading-custom">Add Transaction</div>
              <Button onClick={handleAddTransactionToggle} style={{ cursor: 'pointer' }} className="close-btn">
                X
              </Button>
            </div>
            <div className="row">
              <div className="col-md-4 pt-3">
                <Label for="amountInput" className="custom-label">
                  Amount:
                </Label>
                <Input
                  id="amountInput"
                  type="text"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  placeholder="Enter amount"
                  className="custom-input-box"
                />
                {amountError && <div className="text-danger">{amountError}</div>}
              </div>
              <div className="col-md-4 pt-3">
                <Label for="transactionType" className="custom-label">
                  Transaction Type:
                </Label>
                <Input
                  type="select"
                  id="transactionType"
                  value={transactionType}
                  onChange={e => setTransactionType(e.target.value)}
                  className="custom-input-box"
                >
                  <option value="Deposit">Deposit</option>
                  <option value="Withdrawal">Withdrawal</option>
                </Input>
              </div>
              <div className="col-md-4 pt-3">
                <Label for="transactionNoteInput" className="custom-label">
                  Transaction Note:
                </Label>
                <Input
                  id="transactionNoteInput"
                  type="text"
                  value={transactionNote}
                  onChange={e => setTransactionNote(e.target.value)}
                  placeholder="Enter transaction note"
                  className="custom-input-box"
                />
                {transactionNoteError && <div className="text-danger">{transactionNoteError}</div>}
              </div>
              <div className="col-12 text-end pt-3 pb-3">
                <Button className="generate-report-btn" onClick={handleAddTransaction}>
                  Add Transaction
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      )}

      {showTransactionHistory && (
        <div className="mt-4 mainContainer table-wrapper custom-table-responsive">
          {errorMessage ? (
            <div className="alert alert-danger">{errorMessage}</div>
          ) : transactionTable?.length > 0 ? (
            <Table responsive className="table-striped table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Lender Balance Account Unique Id</th>
                  <th>Opening Value</th>
                  <th>Closing Value</th>
                  <th>Amount</th>
                  <th>Transaction Type</th>
                  <th>Note</th>
                  <th>Timestamp</th>
                  <th>Loan Product Type</th>
                  <th>Loan Account ID</th>
                  <th>Loan Application Unique Id</th>
                  <th>Principal Amount</th>
                  <th>Interest Amount</th>
                  <th>Flexi Daily Fee Amount</th>
                  <th>Penality</th>
                  <th>Servicing Fee</th>
                  <th>Instalment Unique Id</th>
                  <th>Instalment Number</th>
                  <th>Instalment Due Date</th>
                  <th>Instalment Status</th>
                  <th>Nofrixion Unique Id</th>
                  <th>Nofrixion Reference</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                </tr>
              </thead>
              <tbody>
                {transactionTable.map((transaction, index) => (
                  <tr key={index}>
                    <td>{transaction.id ?? 0}</td>
                    <td>{transaction.lender_balance_account_unique_id ?? 'N/A'}</td>
                    <td>{transaction.opening_value ?? 0}</td>
                    <td>{transaction.closing_value ?? 0}</td>
                    <td>{transaction.value_amount ?? 0}</td>
                    <td>{transaction.transaction_type ?? 'N/A'}</td>
                    <td>{transaction.note ?? 'N/A'}</td>
                    <td>{new Date(transaction.timestamp).toLocaleString() ?? 'N/A'}</td>
                    <td>{transaction.loan_product_type ?? 'N/A'}</td>
                    <td>{transaction.loan_account_unique_id ?? 'N/A'}</td>
                    <td>{transaction.loan_application_unique_id ?? 'N/A'}</td>
                    <td>{transaction.principal_amount ?? 0}</td>
                    <td>{transaction.interest_amount ?? 0}</td>
                    <td>{transaction.flexi_daily_fee_amount ?? 0}</td>
                    <td>{transaction.penalty ?? 'N/A'}</td>
                    <td>{transaction.servicing_fee ?? 'N/A'}</td>
                    <td>{transaction.instalment_unique_id ?? 'N/A'}</td>
                    <td>{transaction.instalment_number ?? 0}</td>
                    <td>{transaction.instalment_due_date ?? 'N/A'}</td>
                    <td>{transaction.instalment_status ?? 'N/A'}</td>
                    <td>{transaction.nofrixion_unique_id ?? 'N/A'}</td>
                    <td>{transaction.nofrixion_reference ?? 'N/A'}</td>
                    <td>{transaction.created_at ?? 'N/A'}</td>
                    <td>{transaction.updated_at ?? 'N/A'}</td>
                    <td>{transaction.created_by ?? 'N/A'}</td>
                    <td>{transaction.last_updated_by ?? 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>No transactions found.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default LenderInvestment;
