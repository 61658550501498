import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import { faSpinner, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Buffer } from 'buffer';

const TermLoanGeneralLedger = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const credentials = Buffer.from(JASPER_CREDENTIALS, 'base64').toString('utf-8');
    const [username, password] = credentials.split(':');
  const fetchReport = async () => {
    try {
      const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/General_Ledger_Report.pdf`, {
        auth: {
          username,
          password,
        },
        responseType: 'arraybuffer',
      });
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setPdfUrl(fileURL);
      setErrorMessage(null);
    } catch (err) {
      console.error('Error fetching the report:', err);
      setErrorMessage('Failed to load report. Please try again later.');
    }
  };
  const downloadReportCSV = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${REPORT_URL}/jasperserver/rest_v2/reports/jasper/General_Ledger_Report.csv`, {
        auth: {
          username,
          password,
        },
        responseType: 'arraybuffer',
      });
      const file = new Blob([response.data], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `General_Ledger_Report.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setErrorMessage(null);
    } catch (err) {
      console.error('Error fetching the report:', err);
      setErrorMessage('Failed to load report');
    }
    finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <div className='pt-3'>
      <h2>General Ledger</h2>
      <div className="col-md-12 mb-3">
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
        {pdfUrl ? (
          <div className="d-flex justify-content-end">
            <Button className="ms-2  generate-report-btn" onClick={downloadReportCSV}>
              {loading ?
                <>
                  <FontAwesomeIcon icon={faSpinner} spin={loading} />
                  &nbsp;Preparing Report
                </> : <>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;Download CSV
                </>
              }
            </Button>
          </div>
        ) : (
          <> </>
        )}
      </div>
      {pdfUrl ? (
        <iframe src={pdfUrl} title="Report Viewer" width="100%" height="600px"></iframe>
      ) : (
        !errorMessage && <div>Loading report...</div>
      )}
    </div>
  );
};

export default TermLoanGeneralLedger;
